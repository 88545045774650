.button-group .select-container .select-button {
    min-width: 75px;
}
.table-accordion {
    nb-icon {
        position: relative !important;
        right: 0px !important;
    }
}

.accordion-item-header-collapsed nb-icon.expansion-indicator {
    rotate: -90deg;
}
.accordion-item-header-expanded nb-icon.expansion-indicator {
    rotate: -180deg;
}